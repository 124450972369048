// export const API = "https://ourntamockpapers.onrender.com/api";
export const API = "https://api.testknock.com/api";

export const subtopicsData = {
  QUANTITATIVE_APTITUDE: [
    "NUMBER SYSTEM",
    "AVERAGE",
    "PERCENTAGE",
    "PROFIT AND LOSS",
    "S.I AND C.I",
    "PARTNERSHIP",
    "RATIO AND PROPORTION",
    "TIME AND WORK",
    "TIME, SPEED AND DISTANCE",
    "PROBABILITY",
    "PERMUTATION AND COMBINATION",
    "GEOMETRY"
  ],
  DATA_INTERPRETATION: ["BAR CHART", "PIE CHART", "TABLE CHART", "LINE CHART"],
  LOGICAL_REASONING: [
    "CODING AND DECODING",
    "FAMILY TREE AND BLOOD RELATIONS",
    "DIRECTIONS",
    "NUMBER OR ALPHABET SERIES",
    "PUZZLES",
    "CRITICAL REASONING",
    "SITUATION TEST",
    "MISCELLANEOUS",
    "ARRANGEMENTS",
  ],
  VERBAL_ABILITY_AND_READING_COMPREHENSION: [
    "SENTENCE CORRECTION",
    "READING COMPREHENSION",
    "CRITICAL REASONING",
    "SENTENCE COMPLETION",
    "SYLLOGISMS",
    "SYNONYMS",
    "ANTONYMS",
    "ONE WORD SUBSTITUTION",
    "IDIOMS AND PHRASES",
    "ANALOGY",
  ],
};
